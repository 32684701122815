import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { CWMarkdownComponent } from './cw-markdown/cw-markdown.component'
import { MarkdownEditorComponent } from './markdown-editor/markdown-editor.component'

@NgModule({
  declarations: [CWMarkdownComponent, MarkdownEditorComponent],
  imports: [
    // Angular Dependencies
    CommonModule,
    FormsModule,

    // Material
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [CWMarkdownComponent, MarkdownEditorComponent],
})
export class DesignModule {}
