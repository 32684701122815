<form novalidate [formGroup]="form" class="file-upload-form">
  <input
    type="file"
    id="file-upload"
    (change)="onChange($event)"
    accept=".xlsx,.docx,.csv,.pdf,.pptx,.png,.jpg,.jpeg,.bmp,.tiff,.txt,.md"
    [disabled]="disableButton"
    title="Supported documents are csv, xlsx, docx, md, pdf, pptx, png, jpg, jpeg, bmp, tiff, txt, md"
  />
  <label
    for="file-upload"
    class="file-upload-label"
    *ngIf="!showProgress && !selectedFile"
  >
    <i class="mdi mdi-paperclip file-upload-form-upload-icon"></i> File Upload
  </label>
  <div class="file-upload-form-spinner" *ngIf="showProgress">
    <mat-spinner diameter="20"></mat-spinner>
  </div>

  <!-- Display filename and cancel icon when a file is selected -->
  <div *ngIf="selectedFile && !showProgress" class="selected-file">
    <span title="{{ selectedFile }}">{{ selectedFile }}</span>
    <i
      class="mdi mdi-close"
      (click)="cancelFileSelection()"
      (keypress)="
        ($event.key === 'Enter' || $event.key === ' ') && cancelFileSelection()
      "
      title="Remove file"
    ></i>
  </div>
</form>
