import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import {
  AvailableAction,
  ChatMessageModel,
  DigitalSpecialist,
  UserFeedback,
} from '@cwan-gpt-ui/models'
import {
  RootState,
  messageFeedbackAction,
  selectChatHistory,
  selectCopilotChatStatus,
  selectIsAwaitingResponse,
  selectIsDebugModeSet,
  setMessageFeedback,
} from '@cwan-gpt-ui/state-management'
import { Store, select } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: [`./chat-widget.component.scss`],
})
export class ChatWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('chatContentContainer')
  private readonly chatContentContainer!: ElementRef

  @HostBinding('class') classes = 'mat-app-background'

  @Output() messageSend = new EventEmitter<string>()
  @Output() actionSelect = new EventEmitter<AvailableAction>()
  @Input() enableTempFileUpload = false

  chatHistory: ChatMessageModel[] = []
  numberOfChatsInHistory = 0
  showTyping = false
  debug = false
  specialist?: DigitalSpecialist
  chatEnabled = true

  private readonly _destroyed$ = new Subject()

  constructor(private readonly store: Store<RootState>) {}

  ngOnInit(): void {
    this.store
      .select(selectChatHistory)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((chatHistory) => {
        this.handleChatHistoryUpdated(chatHistory)
      })
    this.store
      .select(selectIsAwaitingResponse)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((isAwaitingResponse) => {
        this.showTyping = isAwaitingResponse
      })
    this.store
      .select(selectIsDebugModeSet)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((isDebugModeSet) => {
        this.debug = isDebugModeSet
        this.handleChatHistoryUpdated(this.chatHistory)
      })

    this.store
      .pipe(select(selectCopilotChatStatus), takeUntil(this._destroyed$))
      .subscribe((copilotChatStatus) => {
        this.chatEnabled = copilotChatStatus.chatEnabled
      })
  }
  ngOnDestroy(): void {
    this._destroyed$.next()
    this._destroyed$.complete()
  }

  private handleChatHistoryUpdated(chatHistory: ChatMessageModel[]) {
    this.chatHistory = chatHistory

    // If new messages are not added, do not scroll.
    // Stops the chat from scrolling when Feedback is sent.
    if (this.numberOfChatsInHistory === chatHistory.length) return
    this.numberOfChatsInHistory = chatHistory.length

    //give some time to update, then scroll
    setTimeout(() => this.scrollToTopOfLastMessage())
  }

  handleMessageSend(message: string) {
    this.messageSend.emit(message)
  }

  handleActionSelect(action: AvailableAction) {
    this.actionSelect.emit(action)
  }

  handleFeedback(feedback: UserFeedback | undefined, messageIndex: number) {
    this.store.dispatch(
      setMessageFeedback({
        messageIndex,
        feedback,
      })
    )
    this.store.dispatch(
      messageFeedbackAction({
        messageIndex,
        feedback,
      })
    )
  }

  scrollToTop(): void {
    try {
      this.chatContentContainer.nativeElement.scrollTop = 0
    } catch (err) {
      return
    }
  }

  scrollToTopOfLastMessage(): void {
    try {
      const chatContainer = this.chatContentContainer.nativeElement
      const lastChatMessage = chatContainer.children[0].lastElementChild

      chatContainer.scrollTop =
        chatContainer.scrollHeight - lastChatMessage.offsetHeight
    } catch (err) {
      return
    }
  }
}
