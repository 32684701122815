import { ChatMessageModel } from './chat.model'

export interface ModelInfo {
  temperature?: number

  //V1
  deploymentName?: string
  modelName?: string

  //V2
  provider?: string
  deployment?: string
  model?: string
}

export const defaultModel: ModelInfo = {
  provider: 'azure-openai',
  deployment: 'gpt-4o-mini',
  model: 'gpt-4o-mini',
  temperature: 0.9,
}

export const defaultDSModel: ModelInfo = {
  provider: 'azure-openai',
  model: 'gpt-4o',
  temperature: 0.9,
}

export interface ChatOptions {
  tools?: string[] | Tool[]
  cwanSystemPrompts?: boolean
  file?: string
  dsUsername?: string
}

export interface MessageFile {
  file: string
  display_name?: string
}

export interface Tool {
  name: string
  options?: {
    user_id?: string //number
    filenames?: string[]
    sources?: string[]
  }
}

export interface ChatPrompt {
  name: string
  prompt: string
}

export interface ModelUsage {
  responseTime: number
  completionTokens: number
  promptTokens: number
  totalTokens: number
}

export interface ChatRequest {
  modelInfo: ModelInfo
  messages: ChatMessageModel[]
  options?: ChatOptions
  isPrivateMode?: boolean
}

export interface ChatResponse {
  created: string
  model: string
  message: ChatMessageModel
  usage: ModelUsage
}

export interface Tool {
  name: string
  exclusiveSelection?: boolean
  isDisabled?: boolean
  samplePrompts?: string[]
}

export interface PdfAction {
  messageText: string
}

export interface UserPolicyResponse {
  hasBetaFeatureAccess?: boolean
  hasDebugToolsAccess?: boolean
  hasPromptTemplateAdmin?: boolean
  hasDigitalSpecialistAccess?: boolean
  hasDigitalSpecialistAdmin?: boolean
}

export interface ToolPrompt {
  tool: string
  prompts: string[]
}

export interface ToolConfig {
  toolId: string
  name: string
  type: string
  description: string
  usageDescription: string
  uri: string
  isPlaintext: boolean
  samplePrompts: string[]
}

export interface CiamUsersResponse {
  continuationToken: string
  items: CiamUser[]
  total: number
}
export interface CiamUser {
  email: string
  name: string
  resourceName?: string
  tenantId?: string
  userId?: string
}
