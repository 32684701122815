export interface GlobalResourcesFileUploadResponse {
  url: string
}

export interface ReleaseNote {
  id?: string
  title: string
  summary?: string
  content: string
  created: string // in ISO format: "2022-03-10T12:15:50",
  modified: string // in ISO format: "2022-03-10T12:15:50"
}

export interface ReleaseNotesQueryParams {
  limit?: number
  search?: string
  fromId?: number
}

export interface ReleaseNotesResponse {
  nextToken?: string
  releases: ReleaseNote[]
}

export const fakeReleaseNotes: ReleaseNote[] = [
  {
    id: '1',
    created: new Date().toISOString(),
    modified: new Date().toISOString(),
    title:
      'Introducing Our Cutting-Edge Digital Specialists, Revolutionizing The Way We Work',
    summary:
      'Our new Digital Specialists represent a significant milestone in the evolution of Clearwater and the way we work - we are thrilled to announce the release of the first three Digital Specialists: Annie, Clara, and Nikki.',
    content: `Key Details

### Annie - AWS ETL Error Resolution Assistance and ACE Resolution Assistance

- Based on the query, Annie contacts Tiresias to get information on ETL errors and ACEs, looks up the error on confluence and returns the precise instructions for you to resolve the issue.

### Clara - Book Yield Tie Out

- Clara generates a comprehensive Excel document that contains all the essential details for the Book Yield Tie Out. This carefully prepared document ensures accurate and reliable tie out information for a seamless verification process.

### Nikki - NAIC

- Nikki is highly knowledgeable and well-versed in NAIC regulatory matters. She can confidently address all kinds of NAIC regulatory questions and provide well-formulated responses that can be utilized for both internal and external queries.

* Accessing the Specialists

Annie, Clara, and Nikki are easily accessed via Crystal and available NOW! As more Digital Specialists are released, they will appear in the Digital Specialist lounge.
`,
  },
]
