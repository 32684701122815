// Angular imports
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'

// Third-party imports
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

// Project-specific imports
import { DigitalSpecialistSession } from '@cwan-gpt-ui/models'
import { FileUploadService } from '@cwan-gpt-ui/services'
import {
  clearTemporaryFiles,
  RootState,
  selectActiveSession,
  selectTemporaryFiles,
  setTemporaryFiles,
} from '@cwan-gpt-ui/state-management'

@Component({
  selector: 'temp-file-upload',
  templateUrl: './temp-file-upload.component.html',
  styleUrls: ['./temp-file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TempFileUploadComponent implements OnInit, OnDestroy {
  constructor(
    private readonly fileUploadService: FileUploadService,
    private readonly toastr: ToastrService,
    private readonly store: Store<RootState>
  ) {}

  selectedFiles = new UntypedFormControl()
  selectedFile?: string

  disableButton = false
  showProgress = false
  trackProgressTimeout = 0
  timeout = 2000
  uploadStatusErrorCount = 0
  private readonly destroyed$ = new Subject<void>()
  private session?: DigitalSpecialistSession

  public form: UntypedFormGroup = new UntypedFormGroup({
    selectedFiles: this.selectedFiles,
  })
  resetForm() {
    this.disableButton = false
    this.showProgress = false
    this.selectedFiles = new UntypedFormControl()
    this.form = new UntypedFormGroup({
      selectedFiles: this.selectedFiles,
    })
  }

  ngOnInit(): void {
    this.store
      .select(selectActiveSession)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((session) => {
        this.session = session
      })

    this.store
      .select(selectTemporaryFiles)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((files) => {
        this.selectedFile = files[this.session?.userId || '']?.[0]
      })
  }

  cancelFileSelection() {
    this.selectedFile = undefined
    this.store.dispatch(clearTemporaryFiles({ id: this.session?.userId || '' }))
    const fileInput: HTMLInputElement = document.getElementById(
      'file-upload'
    ) as HTMLInputElement
    if (fileInput) {
      fileInput.value = '' // Clear the input value
    }
  }

  async onChange(event: Event) {
    const input = event.target as HTMLInputElement
    if (!input.files || input.files.length === 0) {
      return
    }

    const selectedFiles = Array.from(input.files)
    this.disableButton = false
    const fileName = selectedFiles[0].name.replace(/[|+]/g, '_')

    this.disableButton = true
    this.showProgress = true
    const file = selectedFiles[0]
    const fileBlob = new Blob([new Uint8Array(await file.arrayBuffer())], {
      type: file.type,
    })
    const formData = new FormData()
    formData.append(
      'file',
      fileBlob,
      this.changeFileExtensionToLowercase(fileName)
    )

    this.fileUploadService
      .uploadFile(formData, this.session?.sessionId)
      .then((response) => {
        const jobId = response?.jobId
        if (jobId) this.trackUploadProgress(jobId, fileName)
      })
      .catch((error) => {
        if (error) {
          this.resetForm()
          this.toastr.error(
            error?.error || 'File Upload Failed, please retry again.'
          )
        }
      })
  }

  async trackUploadProgress(jobId: string, fileName: string) {
    try {
      const status = await this.fileUploadService.getUploadStatus(jobId)
      this.uploadStatusErrorCount = 0
      if (status.stage === 'ERROR') {
        this.resetForm()
        this.toastr.error('File Upload Failed, please retry again.')
        await this.fileUploadService.deleteFile(fileName)
      } else if (status.percent_complete === 100) {
        setTimeout(() => {
          this.resetForm()
        }, 2000)

        this.toastr.success('File Uploaded successfully')
        this.store.dispatch(
          setTemporaryFiles({
            filenames: [fileName],
            sessionId: this.session?.userId || '',
          })
        )
        this.disableButton = false
      } else {
        this.trackProgressTimeout = setTimeout(() => {
          this.timeout = Math.min(7000, this.timeout * 1.25)
          this.trackUploadProgress(jobId, fileName)
        }, this.timeout)
      }
    } catch (error) {
      console.error(error)
      if (++this.uploadStatusErrorCount < 4) {
        this.trackProgressTimeout = setTimeout(() => {
          this.trackUploadProgress(jobId, fileName)
        }, 2000 * this.uploadStatusErrorCount)
      } else {
        this.resetForm()
        this.toastr.error('File Upload Failed, please retry again.')
        await this.fileUploadService.deleteFile(fileName)
      }
    }
  }

  private changeFileExtensionToLowercase(filename: string) {
    const parts = filename.split('.')
    const extension = parts.pop()?.toLowerCase()
    return `${parts.join('.')}.${extension}`
  }

  ngOnDestroy(): void {
    clearTimeout(this.trackProgressTimeout)
  }
}
