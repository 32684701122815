import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { BrowserModule } from '@angular/platform-browser'
import { RouterModule, Routes } from '@angular/router'
import { AiReconcilerModule } from '@cwan-gpt-ui/ai-reconciler'
import { CwanGPTChatModule } from '@cwan-gpt-ui/cwan-chat'
import { DesignModule } from '@cwan-gpt-ui/design'
import { PromptTemplateModule } from '@cwan-gpt-ui/libs/prompt-template'
import { AuthTokenInterceptor } from '@cwan-gpt-ui/services'
import { StateManagementModule } from '@cwan-gpt-ui/state-management'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { AppContainerModule, ButtonModule } from '@northfork/ng-basics'
import { EnvDirective } from '../directives/env/env.directive'
import { AppComponent } from './app.component'
import { AdBlockerComponent } from './pages/adblocker-page/ad-blocker.component'
import { AiReconcilerPageComponent } from './pages/ai-reconciler-page/ai-reconciler-page.component'
import { ChatAnalyzerComponent } from './pages/chat-analyzer-page/chat-analyzer-page.component'
import { ChatAnalyzerModule } from './pages/chat-analyzer-page/chat-analyzer/src'
import { DigitalSpecialistPageComponent } from './pages/digital-specialist-page/digital-specialist-page.component'
import { FaqPageComponent } from './pages/faq-page/faq-page.component'
import { HomePageComponent } from './pages/home-page/home-page.component'
import { TemplatePageComponent } from './pages/template-page/template-page.component'

export const routes: Routes = [
  {
    path: ``,
    component: HomePageComponent,
  },
  {
    path: 'template',
    component: TemplatePageComponent,
  },
  {
    path: 'digital-specialist',
    component: DigitalSpecialistPageComponent,
  },
  {
    path: `faq`,
    component: FaqPageComponent,
  },
  {
    path: `adblocker`,
    component: AdBlockerComponent,
  },
  {
    path: 'ai-reconciler',
    loadChildren: () =>
      import('@cwan-gpt-ui/ai-reconciler').then((m) => m.AiReconcilerModule),
  },
  {
    path: 'chat-analyzer',
    component: ChatAnalyzerComponent,
  },
]

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    TemplatePageComponent,
    FaqPageComponent,
    EnvDirective,
    AdBlockerComponent,
    DigitalSpecialistPageComponent,
    AiReconcilerPageComponent,
    ChatAnalyzerComponent,
  ],
  imports: [
    // Angular Modules
    BrowserModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    MatIconModule,
    //NF Modules
    AppContainerModule,
    ButtonModule,
    CwanGPTChatModule,
    ChatAnalyzerModule,
    // modules for this app
    AiReconcilerModule,
    DesignModule,
    CommonModule,
    PromptTemplateModule,
    StateManagementModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
