<mat-card
  class="chat-message"
  [ngClass]="{
    'chat-message-user': isUser,
    'chat-message-assistant': isAssistant,
    'chat-message-system': isSystem
  }"
>
  <div class="chat-message-content">
    <typing-dots *ngIf="showTyping; else messageText"></typing-dots>
    <ng-template #messageText>
      <cw-markdown [markdownText]="messageContent"></cw-markdown>
    </ng-template>
  </div>

  <div class="chat-temporary-file" *ngIf="uploadedFilename || messageFile">
    <temp-file-ctas
      [filename]="uploadedFilename || messageFile || ''"
      (messageSend)="onMessageSend($event)"
    ></temp-file-ctas>
  </div>

  <div *ngIf="availableActions" class="chat-message-actions">
    <mat-divider
      class="chat-message-actions-divider"
      [ngClass]="{
        'chat-message-action-available-solution': isAvailableSolution
      }"
    ></mat-divider>
    <h4 class="chat-message-section-title" *ngIf="!isAvailableSolution">
      Suggested Next Steps
    </h4>
    <chat-message-actions
      [availableActions]="availableActions"
      (actionClicked)="handleAction($event)"
    ></chat-message-actions>
  </div>

  <div *ngIf="citations" class="chat-message-citations">
    <mat-divider></mat-divider>
    <h4 class="chat-message-section-title">Citations</h4>
    <chat-message-citation
      class="chat-message-citation-item"
      *ngFor="let citation of citations; let i = index"
      [citation]="citation"
      [index]="i"
    ></chat-message-citation>
  </div>

  <div *ngIf="inputForm" class="chat-message-input-form">
    <mat-divider></mat-divider>
    <h4 class="chat-message-section-title">{{ inputForm.title || 'Form' }}</h4>
    <chat-message-input-form
      class="chat-message-input-form-item"
      [inputForm]="inputForm"
      (messageSend)="onMessageSend($event)"
    ></chat-message-input-form>
  </div>

  <div *ngIf="chart" class="chat-message-chart">
    <mat-divider></mat-divider>
    <chat-message-chart [chartOptions]="chart"></chat-message-chart>
  </div>

  <div *ngIf="fileType" class="chat-message-actions">
    <mat-divider></mat-divider>
    <file-upload
      (uploadCompletionEvent)="handleFileUploadComplete($event)"
      [userFiles]="userFiles"
    ></file-upload>
  </div>

  <div
    *ngIf="debug && intermediateSteps"
    class="chat-message-intermediate-steps"
  >
    <span>
      <b>Intermediate Steps</b>
      <button
        mat-icon-button
        aria-label="Copy Intermediate Steps"
        (click)="copyIntermediateSteps()"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
    </span>
    <ngx-json-viewer
      class="intermediate-steps-json-viewer"
      [json]="intermediateSteps"
      [expanded]="false"
    ></ngx-json-viewer>
  </div>

  <div *ngIf="debug && toolDebugLogs" class="chat-message-tool-debug-logs">
    <span>
      <b>Tool Debug Logs</b>
    </span>
    <ul>
      <li *ngFor="let log of toolDebugLogs">
        {{ log }}
      </li>
    </ul>
  </div>

  <mat-divider
    *ngIf="isAssistant && !showTyping && !disableFeedback"
  ></mat-divider>
  <div
    class="chat-message-footer"
    *ngIf="isAssistant && !showTyping && !disableFeedback"
  >
    <div class="footer-left-content">
      <button
        mat-icon-button
        aria-label="Like"
        (click)="toggleLike()"
        class="chat-feedback-button"
        [ngClass]="{
          liked: isLiked
        }"
        fs-data="gpt-message-thumbsup"
      >
        <mat-icon>thumb_up_off_alt</mat-icon>
      </button>
      <button
        mat-icon-button
        aria-label="Dislike"
        (click)="toggleDislike()"
        class="chat-feedback-button"
        [ngClass]="{
          disliked: isDisliked
        }"
        fs-data="gpt-message-thumbsdown"
      >
        <mat-icon>thumb_down_off_alt</mat-icon>
      </button>
    </div>
    <div class="footer-right-content">
      <button
        mat-icon-button
        aria-label="Copy"
        (click)="copyMessage()"
        class="chat-copy-button"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>
  </div>

  <div
    class="chat-message-footer-user"
    *ngIf="isUser && isDsChat && !showTyping && !disableFeedback && useMemories"
  >
    <mat-divider></mat-divider>
    <div class="remember-button-container">
      <button
        mat-icon-button
        aria-label="BookmarkAddIcon"
        (click)="rememberMessage()"
        class="chat-feedback-button"
        matTooltip="Add to long term memory"
      >
        <mat-icon>bookmark_add</mat-icon>
      </button>
    </div>
  </div>
</mat-card>
