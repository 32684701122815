<div class="markdown-editor">
  <div
    class="button-container"
    *ngIf="isAdmin"
    [ngClass]="{ 'view-mode': !isEditMode }"
  >
    <div class="edit-button-group">
      <button mat-raised-button type="button" (click)="toggleEditMode()">
        <ng-container *ngIf="!isEditMode">
          <mat-icon>edit</mat-icon> Edit
        </ng-container>

        <ng-container *ngIf="isEditMode">
          <mat-icon>close</mat-icon> Cancel
        </ng-container>
      </button>
      <ng-container
        *ngIf="supportImageUpload"
        class="upload-image-button-container"
      >
        <input
          type="file"
          accept="image/*"
          title="file-upload"
          id="file-upload"
          #fileInput
          (change)="fileChange($event)"
          style="display: none"
        />

        <button
          mat-raised-button
          *ngIf="isEditMode"
          (click)="triggerFileInput()"
          class="upload-image-button"
          color="accent"
        >
          <mat-icon>upload</mat-icon> Upload Image
        </button>
      </ng-container>
    </div>

    <button
      mat-raised-button
      color="primary"
      *ngIf="isEditMode"
      (click)="saveChanges()"
    >
      <mat-icon>save</mat-icon> Save
    </button>
  </div>

  <ng-container *ngIf="!isEditMode">
    <div class="view-mode">
      <cw-markdown
        [markdownText]="markdown"
        [applyCustomStyle]="false"
      ></cw-markdown>
    </div>
  </ng-container>

  <div *ngIf="isEditMode" class="edit-mode">
    <ng-container *ngIf="hasExtraFields">
      <div class="title">
        <mat-form-field appearance="fill">
          <mat-label>Title</mat-label>
          <input
            matInput
            [(ngModel)]="editableTitle"
            name="title"
            placeholder="Enter release note title"
          />
        </mat-form-field>
      </div>
      <div class="summary">
        <mat-form-field appearance="fill">
          <mat-label>Summary</mat-label>
          <textarea
            matInput
            name="summary"
            [(ngModel)]="editableSummary"
            placeholder="Enter summary"
          ></textarea>
        </mat-form-field>
      </div>
    </ng-container>
    <div class="content-editor">
      <div class="editor">
        <textarea [(ngModel)]="editableMarkdown" #markdownTextarea></textarea>
      </div>
      <div class="preview">
        <cw-markdown
          [markdownText]="editableMarkdown"
          [applyCustomStyle]="false"
        ></cw-markdown>
      </div>
    </div>
  </div>
</div>
