import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { GlobalResourcesService } from '@cwan-gpt-ui/services'
import {
  RootState,
  selectHasDigitalSpecialistAdmin,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss'],
})
export class MarkdownEditorComponent implements OnInit, OnDestroy {
  @Input() set markdown(value: string | undefined) {
    this._markdown = value ?? ''
    this.editableMarkdown = this._markdown
  }
  get markdown(): string {
    return this._markdown
  }
  private _markdown = ''

  @Input() set isCreateMode(value: boolean) {
    this._isCreateMode = value
    if (this._isCreateMode) {
      this.isEditMode = true
    }
  }
  get isCreateMode(): boolean {
    return this._isCreateMode
  }
  private _isCreateMode = false

  @Input() set summary(value: string | undefined) {
    this._summary = value ?? ''
    if (this.isCreateMode) {
      this.editableSummary = this._summary
    }
  }
  get summary(): string {
    return this._summary
  }
  private _summary = ''

  @Input() set title(value: string | undefined) {
    this._title = value ?? ''
    if (this.isCreateMode) {
      this.editableTitle = this._title
    }
  }
  get title(): string {
    return this._title
  }
  private _title = ''

  @Input() hasExtraFields = false
  @Input() isUpdateMode = false
  @Input() supportImageUpload = false

  @Output() markdownChange = new EventEmitter<string>()
  @Output() saveContent = new EventEmitter<string>()
  @Output() saveAll = new EventEmitter<{
    title?: string
    summary?: string
    content?: string
  }>()
  @Output() cancelEditClick = new EventEmitter<void>()

  private readonly _destroyed$ = new Subject()

  isEditMode = false
  editableMarkdown = ''
  editableTitle = ''
  editableSummary = ''
  isAdmin = false
  uploadedFileName: string | null = null

  constructor(
    private readonly store: Store<RootState>,
    private readonly snackbar: MatSnackBar,
    private readonly globalResourcesService: GlobalResourcesService
  ) {}

  @ViewChild('fileInput') fileInput!: ElementRef
  @ViewChild('markdownTextarea', { static: false })
  markdownTextarea!: ElementRef

  ngOnInit(): void {
    this.store
      .select(selectHasDigitalSpecialistAdmin)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((hasAdmin) => {
        this.isAdmin = hasAdmin
      })

    this.editableMarkdown = this.markdown
    this.editableSummary = this.summary
    if (this.isCreateMode) {
      this.isEditMode = true
      this.editableTitle = this.title
    }

    if (this.isCreateMode || this.isUpdateMode) {
      this.isEditMode = true
      this.editableTitle = this.title
      this.editableSummary = this.summary
      this.editableMarkdown = this.markdown
    }
  }

  // isCreateMode - from parent
  // isEditMode
  // isUpdateMode - from parent
  toggleEditMode() {
    this.isEditMode = !this.isEditMode

    if (!this.isEditMode) {
      this.cancelEditClick.emit()
    }
  }

  updatePreview() {
    this.markdownChange.emit(this.editableMarkdown)
  }

  saveChanges() {
    if (this.editableMarkdown.trim() === '') {
      this.snackbar.open('Content is required', 'Close', {
        duration: 3000,
        horizontalPosition: 'right',
      })
      return
    }

    if ((this.isCreateMode || this.isUpdateMode) && this.hasExtraFields) {
      this.saveAll.emit({
        title: this.editableTitle,
        summary: this.editableSummary,
        content: this.editableMarkdown,
      })
    } else {
      this.saveContent.emit(this.editableMarkdown)
    }

    this.isEditMode = false
    this.snackbar.open('Update saved', 'Close', {
      duration: 3000,
      horizontalPosition: 'right',
    })
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click()
  }

  async fileChange(event: Event) {
    const target = event.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    if (!file) return

    const textarea = this.markdownTextarea?.nativeElement as HTMLTextAreaElement
    if (!textarea) return

    try {
      const cursorPosition = textarea.selectionStart || 0
      const filePath = `images/${file.name}`

      // Upload and get image URL
      await this.globalResourcesService.uploadImageFile(filePath, file)
      const imageUrl = await this.globalResourcesService.getImageUrl(filePath)

      // Update markdown content
      this.uploadedFileName = file.name
      this.updateMarkdownContent(textarea, cursorPosition, file.name, imageUrl)

      this.showSuccessMessage(file.name)
    } catch (error: any) {
      this.showErrorMessage(error)
    } finally {
      target.value = ''
    }
  }

  private updateMarkdownContent(
    textarea: HTMLTextAreaElement,
    cursorPosition: number,
    fileName: string,
    imageUrl: string
  ) {
    const imageMarkdown = `![${fileName}](${imageUrl})`
    const beforeCursor = this.editableMarkdown.substring(0, cursorPosition)
    const afterCursor = this.editableMarkdown.substring(cursorPosition)

    this.editableMarkdown = beforeCursor + imageMarkdown + afterCursor

    // Restore cursor position
    requestAnimationFrame(() => {
      textarea.focus()
      const newPosition = cursorPosition + imageMarkdown.length
      textarea.setSelectionRange(newPosition, newPosition)
    })
  }

  private showSuccessMessage(fileName: string) {
    this.snackbar.open(`Successfully uploaded: ${fileName}`, 'Close', {
      duration: 3000,
    })
  }

  private showErrorMessage(error: any) {
    this.snackbar.open(
      `Failed to upload file: ${error.message || 'Unknown error'}`,
      'Close',
      {
        duration: 5000,
      }
    )
  }

  clearUploadedFile() {
    this.uploadedFileName = null
  }

  ngOnDestroy(): void {
    this.clearUploadedFile()
    this._destroyed$.next()
    this._destroyed$.complete()
  }
}
