import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface FileUploadRedux {
  placeholder: string
  selectedFiles: string[]
  temporaryFiles: { [key: string]: string[] }
  myDocUploadCompleted: boolean
}

export const fileUploadInitialState: FileUploadRedux = {
  placeholder: 'hi',
  selectedFiles: [],
  temporaryFiles: {},
  myDocUploadCompleted: true,
}

export const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState: fileUploadInitialState,
  reducers: {
    setPlaceholder: (state, action: PayloadAction<{ placeholder: string }>) => {
      state.placeholder = action.payload.placeholder
    },
    setSelectedFiles: (
      state,
      action: PayloadAction<{ selectedFiles: string[] }>
    ) => {
      state.selectedFiles = action.payload.selectedFiles
    },
    setTemporaryFiles: (
      state,
      action: PayloadAction<{ filenames: string[]; sessionId: string }>
    ) => {
      const uniqueFiles = new Set([
        ...(state.temporaryFiles[action.payload.sessionId] || []),
        ...action.payload.filenames,
      ])
      state.temporaryFiles[action.payload.sessionId] = Array.from(uniqueFiles)
    },

    clearTemporaryFiles: (state, action: PayloadAction<{ id: string }>) => {
      state.temporaryFiles[action.payload.id] = []
    },
    setMyDocUploadCompleted: (
      state,
      action: PayloadAction<{ myDocUploadCompleted: boolean }>
    ) => {
      state.myDocUploadCompleted = action.payload.myDocUploadCompleted
    },
  },
})

export const {
  setPlaceholder,
  setSelectedFiles,
  setMyDocUploadCompleted,
  setTemporaryFiles,
  clearTemporaryFiles,
} = fileUploadSlice.actions

export default fileUploadSlice.reducer
