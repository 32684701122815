import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import {
  AvailableAction,
  ChatMessageModel,
  Citation,
  FileType,
  InputForm,
  MessageFile,
  MessageRole,
  UserFeedback,
} from '@cwan-gpt-ui/models'
import { MemoryService } from '@cwan-gpt-ui/services'
import {
  RootState,
  selectActiveSession,
  selectDigitalSpecialist,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import * as Highcharts from 'highcharts'

@Component({
  selector: 'chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: [`./chat-message.component.scss`],
})
export class ChatMessageComponent implements OnInit {
  messageContent = ''
  citations?: Citation[]
  chart?: Highcharts.Options
  availableActions?: AvailableAction[]
  inputForm?: InputForm
  intermediateSteps?: { intermediateSteps: JSON[] }
  fileType?: FileType
  toolDebugLogs?: string[]
  isAssistant = false
  isUser = false
  isSystem = false
  isLiked = false
  isDisliked = false
  disableFeedback?: boolean = false
  isAvailableSolution?: boolean = false
  isDsChat = false
  dsUsername = ''
  currentSessionId = ''
  userFiles: string[] = []
  useMemories = false

  _showTyping = false
  uploadedFilename: string | undefined
  messageFile: string | undefined
  @Input() set showTyping(value: boolean) {
    this._showTyping = value
    this.isAssistant = true
  }
  get showTyping() {
    return this._showTyping
  }

  @Input() debug = false
  @Input() messageIndex?: number

  @Output() actionSelect = new EventEmitter<AvailableAction>()
  @Output() feedback = new EventEmitter<UserFeedback | undefined>()
  @Output() messageSend = new EventEmitter<string>()

  @Input() set message(value: ChatMessageModel) {
    this.messageContent = value.content
    this.setChart(value.chart)
    this.setRole(value.role)
    this.setAvailableActions(value.availableActions)
    this.setCitations(value.citations)
    this.setInputForm(value.inputForm)
    this.setIntermediateSteps(value.intermediateSteps)
    this.setUploadedFilename(value.uploadedFilename)
    this.setMessageFile(value.files)
    this.setFileType(value.fileType)
    this.setToolDebugLogs(value.toolDebugLogs)
    this.setFeedback(value.feedback)
    this.disableFeedback = value.disableFeedback
  }

  ngOnInit(): void {
    this.store.select(selectDigitalSpecialist).subscribe((ds) => {
      if (!ds) {
        this.isDsChat = false
        return
      }
      this.isDsChat = true
      this.dsUsername = ds.username
      this.useMemories = ds.useMemories ?? false
    })

    this.store.select(selectActiveSession).subscribe((session) => {
      if (!session) return
      this.currentSessionId = session.sessionId
    })
  }

  constructor(
    private readonly store: Store<RootState>,
    private readonly snackbar: MatSnackBar,
    private readonly memoryService: MemoryService
  ) {}

  private setRole(role: MessageRole) {
    this.isAssistant = role === 'assistant'
    this.isUser = role === 'user'
    this.isSystem = role === 'system'
  }

  private setAvailableActions(actions: AvailableAction[] | undefined) {
    if (!actions?.length) {
      this.citations = undefined
      return
    }
    this.isAvailableSolution = actions[0]?.type.startsWith('availableSolution')

    this.availableActions = actions
  }

  private setChart(chart: string | undefined) {
    if (!chart) {
      return
    }
    try {
      this.chart = JSON.parse(chart)
    } catch (error) {
      console.error('Error parsing chart', error)
    }
  }

  private setCitations(citations: Citation[] | undefined) {
    if (!citations?.length) {
      this.citations = undefined
      return
    }

    // Remove duplicates from citations
    const results: Record<string, Citation> = {}
    citations.forEach((citation) => {
      results[citation.source] = citation
    })

    this.citations = Object.values(results)
  }

  private setInputForm(inputForm: InputForm | undefined) {
    if (!inputForm) {
      this.inputForm = undefined
      return
    }

    this.inputForm = inputForm
  }

  private setIntermediateSteps(intermediateSteps: JSON[] | undefined) {
    if (!intermediateSteps?.length) {
      this.intermediateSteps = undefined
      return
    }
    this.intermediateSteps = { intermediateSteps }
  }

  private setUploadedFilename(filename?: string) {
    this.uploadedFilename = filename
  }

  private setMessageFile(files?: MessageFile[]) {
    this.messageFile = files?.[0].file
  }

  private setFileType(fileType?: FileType) {
    this.fileType = fileType
  }

  private setToolDebugLogs(toolDebugLogs: string[] | undefined) {
    if (!toolDebugLogs?.length) {
      this.toolDebugLogs = undefined
      return
    }
    this.toolDebugLogs = toolDebugLogs
  }

  private setFeedback(feedback: UserFeedback | undefined) {
    this.isLiked = feedback === 'like'
    this.isDisliked = feedback === 'dislike'
  }

  public onMessageSend(message: string) {
    this.messageSend.emit(message)
  }

  async rememberMessage() {
    try {
      await this.memoryService.insertMemory(
        this.dsUsername,
        this.messageContent
      )
      this.snackbar.open('Message added to long term memory!')
    } catch (err) {
      console.log(err)
      this.snackbar.open('Error inserting message to memory!')
    }
  }

  async copyMessage() {
    if (!this.messageContent) {
      return
    }

    try {
      await navigator.clipboard.writeText(this.messageContent)
    } catch (error) {
      console.error('Error copying to clipboard', error)
    }
  }

  async copyIntermediateSteps() {
    if (!this.intermediateSteps) {
      return
    }

    const intermediateStepsText = JSON.stringify(this.intermediateSteps)

    try {
      await navigator.clipboard.writeText(intermediateStepsText)
    } catch (error) {
      console.error('Error copying intermediate steps to clipboard', error)
    }
  }

  handleAction(action: AvailableAction | undefined) {
    this.actionSelect.emit(action)
  }

  handleFileUploadComplete(event: {
    filename: string
    uniqueFilename: string | undefined
  }) {
    if (this.fileType === FileType.USER_DOCS_FILE) {
      const action: AvailableAction = {
        type: 'availableSolutionUserFile',
        title: `Use the file "${event.filename}"`,
        params: {
          filenames: [event.filename],
        },
      }
      this.actionSelect.emit(action)
    } else if (this.fileType === FileType.MATRIX_GENERATION_FILE) {
      const action: AvailableAction = {
        type: 'generateMatrix',
        title: `Generate Matrix for ${event.filename}`,
        params: {
          filename: event.filename,
        },
      }
      this.actionSelect.emit(action)
    }
  }

  toggleLike() {
    this.feedback.emit(this.isLiked ? undefined : 'like')
  }

  toggleDislike() {
    this.feedback.emit(this.isDisliked ? undefined : 'dislike')
  }
}
