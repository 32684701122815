import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  ReleaseNote,
  ReleaseNotesQueryParams,
  ReleaseNotesResponse,
} from '@cwan-gpt-ui/models'
import { environment } from 'environments/environment'
import { catchError, take } from 'rxjs/operators'

const BASE_URL = environment.cwanGptWsBaseUrl
const RELEASE_NOTES_URL = `${BASE_URL}/digital-specialist-ws/release-notes`

@Injectable({
  providedIn: 'root',
})
export class ReleaseNotesService {
  constructor(private readonly httpClient: HttpClient) {}

  getReleaseNotes(
    queryParams?: ReleaseNotesQueryParams
  ): Promise<ReleaseNotesResponse> {
    const params = queryParams
      ? Object.entries(queryParams).reduce((params, [key, value]) => {
          if (value !== undefined && value !== null) {
            return params.set(key, value.toString())
          }
          return params
        }, new HttpParams())
      : undefined

    return this.httpClient
      .get<ReleaseNotesResponse>(RELEASE_NOTES_URL, { params })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`getReleaseNotes API failed, url: ${RELEASE_NOTES_URL}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  getReleaseNote(version: string): Promise<ReleaseNote> {
    const url = `${RELEASE_NOTES_URL}/${version}`
    return this.httpClient
      .get<ReleaseNote>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`getReleaseNote API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  createReleaseNote(releaseNote: ReleaseNote): Promise<ReleaseNote> {
    return this.httpClient
      .post<ReleaseNote>(RELEASE_NOTES_URL, releaseNote)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(
            `createReleaseNote API failed, url: ${RELEASE_NOTES_URL}`
          )
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  updateReleaseNote(
    version: string,
    releaseNote: ReleaseNote
  ): Promise<ReleaseNote> {
    const url = `${RELEASE_NOTES_URL}/${version}`
    return this.httpClient
      .put<ReleaseNote>(url, releaseNote)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`updateReleaseNote API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }

  deleteReleaseNote(version: string): Promise<void> {
    const url = `${RELEASE_NOTES_URL}/${version}`
    return this.httpClient
      .delete<void>(url)
      .pipe(
        take(1),
        catchError((error) => {
          console.error(`deleteReleaseNote API failed, url: ${url}`)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }
}
