import { Injectable } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import {
  AvailableAction,
  ChatMessageModel,
  ChatSession,
  DigitalSpecialist,
  DigitalSpecialistSession,
  MessageFile,
  Tool,
  defaultDSModel,
} from '@cwan-gpt-ui/models'
import {
  RootState,
  appendSession,
  clearTemporaryFiles,
  deleteSession,
  selectUserId,
  setSelectedSession,
  setSelectedSpecialist,
  setSessions,
} from '@cwan-gpt-ui/state-management'
import { Store } from '@ngrx/store'
import { environment } from 'environments/environment'
import { BehaviorSubject } from 'rxjs'
import { ChatService } from '../chat-service/chat.service'
import { SessionService } from '../session/session.service'
import { DigitalSpecialistApi } from './digital-specialist-api.service'
import { DigitalSpecialistService } from './digital-specialist.service'

@Injectable({
  providedIn: 'root',
})
export class DigitalSpecialistSessionService {
  private currentSession?: DigitalSpecialistSession
  private userId?: string
  private fakeSessionId = 'new-session'

  private privateModeOption = new UntypedFormControl({
    value: false,
    disabled: false,
  })
  privateModeOption$ = new BehaviorSubject<UntypedFormControl>(
    this.privateModeOption
  )

  constructor(
    private readonly dsService: DigitalSpecialistService,
    private readonly dsApi: DigitalSpecialistApi,
    private readonly chatService: ChatService,
    private readonly store: Store<RootState>,
    private readonly sessionService: SessionService
  ) {
    this.store.select(selectUserId).subscribe((userId) => {
      this.userId = userId
    })
  }

  /**
   * Session Management
   * We create sessions only on the client side, and nothing goes to the server-side until a message is sent.
   * So we can call startNewSession() all day long without ever creating anything on the server.
   * This way every time a user starts a chat with a DS, we can show the welcome message and any prompt templates, but nothing permanent is created yet.
   *
   * Legacy Sessions
   * Since the chat-widget works with ChatSession objects, we have to create a legacy chat-session in addition to the DS session.
   * The legacy session is what is sent over to the API to cortex, and saved in the S3 bucket.
   *
   */
  async startNewSession(username: string): Promise<void> {
    if (!this.userId) {
      console.warn('unknown user')
      return
    }
    //clear temp files on session change
    this.clearTempFiles()

    //this session is ephemeral (should never be stored, it only exists in the client side so make applying welcome messages and resetting state easier)
    const dsSession: DigitalSpecialistSession = {
      sessionId: this.fakeSessionId,
      userId: this.userId,
      specialistUsername: username,
      creationDatetime: new Date().toISOString(),
      firstLineText: '',
      summaryText: '',
    }

    const session: ChatSession = {
      createdAt: new Date(),
      id: this.userId,
      history: [],
      model: environment.defaultDSModel || defaultDSModel,
    }

    //now add the welcome message!
    let ds = this.dsService.getCachedSpecialist(username)
    if (!ds) {
      await this.dsService.loadSpecialistDetails(username)
      ds = this.dsService.getCachedSpecialist(username)
    }
    if (!ds) throw new Error(`startSession: cannot find specialist ${username}`)
    const welcomeMessage = this.createWelcomeMessage(ds)
    if (welcomeMessage) session.history.push(welcomeMessage)

    this.activateSessions(dsSession, session)
  }

  async setCurrentSession(dsSession: DigitalSpecialistSession | undefined) {
    //clear temp files on session change
    this.clearTempFiles()

    if (!dsSession) {
      this.currentSession = undefined
      this.chatService.setCurrentSession(undefined)
      this.store.dispatch(setSelectedSession(undefined))
      return
    }

    //load the message history
    let legacySession: ChatSession | undefined
    try {
      legacySession = await this.sessionService.loadSession(dsSession.sessionId)
    } catch (e) {
      //TODO: deleteme 2024-07-01 (attempt to load old ds session from dynamo)
      const messages = await this.dsApi.getDeprecatedSessionMessages(
        dsSession.sessionId
      )
      legacySession = {
        createdAt: new Date(),
        id: dsSession.sessionId,
        history: messages,
        model: environment.defaultDSModel || defaultDSModel,
      }
    }

    this.activateSessions(dsSession, legacySession)
  }

  private clearTempFiles() {
    //clear temp files on session change
    this.store.dispatch(clearTemporaryFiles({ id: this.userId || '' }))
  }

  private activateSessions(
    dsSession: DigitalSpecialistSession,
    session: ChatSession
  ) {
    this.currentSession = dsSession
    this.store.dispatch(setSelectedSpecialist(dsSession.specialistUsername))

    //activate the sessions
    this.chatService.setCurrentSession(session)
    //activate ds session
    this.store.dispatch(setSelectedSession(dsSession))
  }

  async loadSessions(dsUsername: string) {
    if (!this.userId) {
      console.warn('unknown user')
      return
    }

    const topN = 8
    const sessions = await this.dsApi.getAllSessions(dsUsername, topN)
    this.store.dispatch(setSessions(sessions))
  }

  async deleteSession(sessionId: string) {
    await this.dsApi.deleteSession(sessionId)
    await this.sessionService.deleteSession(sessionId)
    this.store.dispatch(deleteSession(sessionId))
  }

  async sendMessage(
    message: string,
    tools: Tool[],
    isPrivateMode: boolean,
    files?: MessageFile[]
  ): Promise<string> {
    const legacySession = this.chatService.getCurrentSession()
    if (!this.currentSession || !legacySession) return ''
    if (!this.userId) {
      console.warn('unknown user')
      return ''
    }

    //if this is the first user prompt, and private mode is not enabled, go ahead and create a DS session on the server, and make that the current session
    if (!this.hasUserMessages(legacySession.history) && !isPrivateMode) {
      const newDsSession = await this.dsApi.createSession(
        this.currentSession.specialistUsername,
        message
      )
      const newSession = { ...legacySession, id: newDsSession.sessionId }

      this.store.dispatch(appendSession(newDsSession))
      this.activateSessions(newDsSession, newSession)
    }

    this.disablePrivateModeToggle()

    return await this.chatService.sendMessage(
      message,
      {
        dsUsername: this.currentSession.specialistUsername,
        tools: tools,
      },
      isPrivateMode,
      files
    )
  }

  private hasUserMessages(history: ChatMessageModel[]): boolean {
    return history.some((m) => m.role == 'user')
  }

  private createWelcomeMessage(
    specialist: DigitalSpecialist
  ): ChatMessageModel | undefined {
    if (
      !specialist.welcomeMessage &&
      specialist.suggestedQuestions.length == 0 &&
      specialist.promptTemplates.length == 0
    ) {
      return undefined
    }

    const welcomeMessage = specialist.welcomeMessage
      ? specialist.welcomeMessage
      : ''

    const questions = specialist.suggestedQuestions.map((q) => ({
      title: `Ask: ${q}`,
      type: 'promptSuggestion',
      params: {
        content: q,
      },
    })) as AvailableAction[]

    const templates = specialist.promptTemplates.map((t) => ({
      title: t,
      type: 'template',
      params: {},
    })) as AvailableAction[]

    const availableActions = [...questions, ...templates]

    return {
      role: 'assistant',
      availableActions,
      content: welcomeMessage,
    } as ChatMessageModel
  }

  /**
   * Check whether the current session is real of was a fake session created by the UI
   * @param session
   */
  isSessionReal(session?: DigitalSpecialistSession): boolean {
    if (!session) return false
    return session.sessionId != this.fakeSessionId
  }

  setPrivateModeOption(enabled: boolean | undefined) {
    this.privateModeOption.setValue(enabled)
  }

  enablePrivateModeToggle() {
    this.privateModeOption.enable()
  }

  disablePrivateModeToggle() {
    this.privateModeOption.disable()
  }
}
